import React, { useState } from 'react';
import { Checkbox } from 'react-md/lib/SelectionControls';
import { AddressModel } from 'Components/Common/AddressInput/AddressModel';
import { AddressInput, PhoneInput } from 'Components/Common';
import {
   ContactEditModel,
   getBusinessAddress,
} from 'Models/Contact/Data/ContactEditModel';
import { TextField } from 'react-md/lib/TextFields';
import { isEqual } from 'lodash-es';
import { ValidationResult } from 'Components/Common/ModelValidator/ValidationModel';
import { ContactValidationFields } from '../CreateContact';
import { usePermissions } from 'Util/Helpers/Permissions';
import '../../../Contact.scss';

interface PublicRegisterSectionProps {
   model: ContactEditModel;
   updateModel: (contact: ContactEditModel) => void;
   validationResult?: ValidationResult<ContactValidationFields>;
   hidePublicRegister?: boolean;
   hideCheckboxesPublicRegister?: boolean;
   disabled?: boolean;
}

const PublicRegisterSection = ({
   model,
   updateModel,
   validationResult,
   hidePublicRegister,
   hideCheckboxesPublicRegister,
   disabled,
}: Readonly<PublicRegisterSectionProps>): JSX.Element => {
   const [businessAddress, setBusinessAddress] = useState(
      getBusinessAddress(model)
   );
   if (!isEqual(getBusinessAddress(model), businessAddress))
      updateModel({ ...model, ...businessAddress });

   const hasModifyPermission = usePermissions('Contact.Update');
   const hasPermissionAbusive = usePermissions('ReferToRegistrar.Abusive');
   const hasPermissionUnpaidFines = usePermissions(
      'ReferToRegistrar.UnpaidFines'
   );
   const hasPermissionDiscplinary = usePermissions(
      'ReferToRegistrar.Discplinary'
   );
   const hasPermissionCannotSupervisePlumbing = usePermissions(
      'ReferToRegistrar.CannotSupervisePlumbing'
   );
   const hasPermissionCannotSuperviseGasfitting = usePermissions(
      'ReferToRegistrar.CannotSuperviseGasfitting'
   );
   const hasPermissionCannotSuperviseDrainlaying = usePermissions(
      'ReferToRegistrar.CannotSuperviseDrainlaying'
   );
   const hasPermissionPersonOfInterest = usePermissions(
      'ReferToRegistrar.PersonOfInterest'
   );
   const hasPermissionCannotTransactOnline = usePermissions(
      'ReferToRegistrar.CannotTransactOnline'
   );
   const hasPermissionCompetenceProgramme = usePermissions(
      'ReferToRegistrar.CompetenceProgramme'
   );

   return (
      <>
         <hr />
         <div className="md-grid md-cell--12 public-register-section">
            {hasModifyPermission && (
               <div
                  className={
                     hideCheckboxesPublicRegister
                        ? 'md-cell md-cell--12 '
                        : 'md-cell md-cell--6 grey-background padding '
                  }
               >
                  <h3>Public Register Details</h3>
                  <div className="md-grid md-cell--12 business-contact-group">
                     <div className="md-cell md-cell--6">
                        <PhoneInput
                           floating
                           id="floating-center-title"
                           label="Business Phone Number"
                           placeholder="Business Phone Number"
                           className="md-cell md-cell--bottom md-cell--12 business-phone-number"
                           onChange={(val): void => {
                              updateModel({
                                 ...model,
                                 publicPhone: val.toString(),
                              });
                           }}
                           value={model.publicPhone}
                           disabled={disabled}
                           required
                        />
                     </div>
                     <div className="md-cell md-cell--6">
                        <TextField
                           floating
                           id="floating-center-title"
                           label="Business Email"
                           lineDirection="center"
                           placeholder="Business Email"
                           className="md-cell md-cell--bottom md-cell--12"
                           type="email"
                           onChange={(val): void => {
                              updateModel({
                                 ...model,
                                 publicEmail: val.toString(),
                              });
                           }}
                           value={model.publicEmail}
                           errorText="Please enter the valid email"
                           disabled={disabled}
                        />
                     </div>
                  </div>
                  <AddressInput
                     floating
                     id="contact_business_address"
                     label="Business Address"
                     placeholder="Business Address"
                     className="md-cell md-cell--bottom md-cell--12"
                     defaultValue={model.businessAddressFull}
                     onSubmit={(val: AddressModel): void => {
                        setBusinessAddress({
                           businessAddressLine1: val.addressLine1,
                           businessAddressLine2: val.addressLine2,
                           businessCity: val.city,
                           businessSuburbTown: val.suburb,
                           businessPostalCode: val.postcode,
                           businessCountryCode: val.countryCode,
                        });
                     }}
                     onChange={(val): void => {
                        if (val) return;

                        setBusinessAddress({
                           businessAddressLine1: '',
                           businessAddressLine2: '',
                           businessCity: '',
                           businessSuburbTown: '',
                           businessPostalCode: '',
                           businessCountryCode: '',
                        });
                     }}
                     disabled={disabled}
                  />
                  {!hideCheckboxesPublicRegister && (
                     <div className="md-grid md-cell--12 business-address-check">
                        <div className="md-cell md-cell--6">
                           <Checkbox
                              id="checkbox-exclude"
                              name="simple-checkboxes[]"
                              label="Exclude Details from Public Register"
                              value="material-design"
                              onChange={(val): void => {
                                 updateModel({
                                    ...model,
                                    excludeContactDetailsFromRegister: val,
                                 });
                              }}
                              defaultChecked={
                                 model.excludeContactDetailsFromRegister
                              }
                              disabled={disabled}
                           />
                        </div>
                        <div className="md-cell md-cell--6">
                           <Checkbox
                              id="checkbox-no-address"
                              name="simple-checkboxes[]"
                              label="I do not have a business address and do not want to provide a street address for public view."
                              value="material-design"
                              onChange={(val): void => {
                                 updateModel({
                                    ...model,
                                    hideInPublicRegisterFlag: val,
                                 });
                              }}
                              defaultChecked={model.hideInPublicRegisterFlag}
                              disabled={disabled}
                           />
                        </div>
                     </div>
                  )}
               </div>
            )}
            <div
               className="md-cell md-cell--6 padding"
               hidden={hidePublicRegister}
            >
               <h3>Refer to Registrar</h3>
               <div className="md-grid md-cell--12">
                  <div className="md-cell md-cell--4">
                     <Checkbox
                        id="checkbox-abusive"
                        name="simple-checkboxes[]"
                        label="Refer to Registrar"
                        value="material-design"
                        onChange={(val): void => {
                           if (hasPermissionAbusive) {
                              updateModel({
                                 ...model,
                                 abusiveToStaff: val,
                              });
                           }
                        }}
                        defaultChecked={model.abusiveToStaff}
                        disabled={!hasPermissionAbusive && disabled}
                     />
                  </div>
                  <div className="md-cell md-cell--4">
                     <Checkbox
                        id="checkbox-fine"
                        name="simple-checkboxes[]"
                        label="Unpaid Fine"
                        value="material-design"
                        onChange={(val): void => {
                           if (hasPermissionUnpaidFines) {
                              updateModel({
                                 ...model,
                                 unpaidFine: val,
                              });
                           }
                        }}
                        defaultChecked={model.unpaidFine}
                        disabled={!hasPermissionUnpaidFines && disabled}
                     />
                  </div>

                  <div className="md-cell md-cell--4">
                     <Checkbox
                        id="checkbox-disciplinary"
                        name="simple-checkboxes[]"
                        label="Disciplinary"
                        value="material-design"
                        onChange={(val): void => {
                           if (hasPermissionDiscplinary) {
                              updateModel({
                                 ...model,
                                 disciplinary: val,
                              });
                           }
                        }}
                        defaultChecked={model.disciplinary}
                        disabled={!hasPermissionDiscplinary && disabled}
                     />
                  </div>
               </div>
               <div className="md-grid md-cell--12">
                  <div className="md-cell md-cell--4">
                     <Checkbox
                        id="checkbox-cannot-supervise_plumber"
                        name="simple-checkboxes[]"
                        label="Cannot Supervise Plumber"
                        value="material-design"
                        onChange={(val): void => {
                           if (hasPermissionCannotSupervisePlumbing) {
                              updateModel({
                                 ...model,
                                 cannotSupervisePlumber: val,
                              });
                           }
                        }}
                        defaultChecked={model.cannotSupervisePlumber}
                        disabled={
                           !hasPermissionCannotSupervisePlumbing && disabled
                        }
                     />
                  </div>
                  <div className="md-cell md-cell--4">
                     <Checkbox
                        id="checkbox-cannot-supervise_gasfitter"
                        name="simple-checkboxes[]"
                        label="Cannot Supervise Gasfitter"
                        value="material-design"
                        onChange={(val): void => {
                           if (hasPermissionCannotSuperviseGasfitting) {
                              updateModel({
                                 ...model,
                                 cannotSuperviseGasfitter: val,
                              });
                           }
                        }}
                        defaultChecked={model.cannotSuperviseGasfitter}
                        disabled={
                           !hasPermissionCannotSuperviseGasfitting && disabled
                        }
                     />
                  </div>
                  <div className="md-cell md-cell--4">
                     <Checkbox
                        id="checkbox-cannot-supervise_drainlayer"
                        name="simple-checkboxes[]"
                        label="Cannot Supervise Drainlayer"
                        value="material-design"
                        onChange={(val): void => {
                           if (hasPermissionCannotSuperviseDrainlaying) {
                              updateModel({
                                 ...model,
                                 cannotSuperviseDrainlayer: val,
                              });
                           }
                        }}
                        defaultChecked={model.cannotSuperviseDrainlayer}
                        disabled={
                           !hasPermissionCannotSuperviseDrainlaying && disabled
                        }
                     />
                  </div>
               </div>
               <div className="md-grid md-cell--12">
                  <div className="md-cell md-cell--4">
                     <Checkbox
                        id="checkbox-person-of-interest"
                        name="simple-checkboxes[]"
                        label="Intel"
                        value="material-design"
                        onChange={(val): void => {
                           if (hasPermissionPersonOfInterest) {
                              updateModel({
                                 ...model,
                                 personOfInterest: val,
                              });
                           }
                        }}
                        defaultChecked={model.personOfInterest}
                        disabled={!hasPermissionPersonOfInterest && disabled}
                     />
                  </div>
                  <div className="md-cell md-cell--4">
                     <Checkbox
                        id="checkbox-cannot-transact"
                        name="simple-checkboxes[]"
                        label="Cannot Transact Online"
                        value="material-design"
                        onChange={(val): void => {
                           if (hasPermissionCannotTransactOnline) {
                              updateModel({
                                 ...model,
                                 cannotTransactOnline: val,
                              });
                           }
                        }}
                        defaultChecked={model.cannotTransactOnline}
                        disabled={
                           !hasPermissionCannotTransactOnline && disabled
                        }
                     />
                  </div>
                  <div className="md-cell md-cell--4">
                     <Checkbox
                        id="checkbox-competence-programme"
                        name="simple-checkboxes[]"
                        label="Competence Programme"
                        value="material-design"
                        onChange={(val): void => {
                           if (hasPermissionCompetenceProgramme) {
                              updateModel({
                                 ...model,
                                 competenceProgramme: val,
                              });
                           }
                        }}
                        defaultChecked={model.competenceProgramme}
                        disabled={!hasPermissionCompetenceProgramme && disabled}
                     />
                  </div>
               </div>
               <div className="md-grid md-cell--12">
                  <div className="md-cell md-cell--8">
                     <Checkbox
                        id="checkbox-discuss-with-registrar"
                        name="simple-checkboxes[]"
                        label="Discuss with Registrar before taking action"
                        value="material-design"
                        onChange={(val): void => {
                           updateModel({
                              ...model,
                              discussWithRegistrar: val,
                           });
                        }}
                        defaultChecked={model.discussWithRegistrar}
                        disabled={disabled}
                     />
                  </div>
                  <div className="md-cell md-cell--4" />
               </div>
            </div>
         </div>
      </>
   );
};

export default PublicRegisterSection;
