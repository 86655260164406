import Contact from 'Models/Contact/Data/Contact';
import {
   CONTACT_TYPE_PRACTITIONER,
   COMPANY_CONTACT_TYPES,
   CONTACT_TYPE_EMPLOYER,
   CONTACT_TYPE_TRAINING_PROVIDER,
} from 'Util/Constants/Contact';
import { formatRegistrationNumber } from './RegistrationNumber';
import moment from 'moment-timezone';
import { NZ_TIMEZONE, DISPLAY_DATE_FORMAT } from 'Util/Constants/Common';

// Returns true if contact has any true RTR flags
export const isContactFlagged = (contact: Contact): boolean => {
   return !!(
      contact.cannotSuperviseDrainlayer ||
      contact.cannotSuperviseGasfitter ||
      contact.cannotSupervisePlumber ||
      contact.abusiveToStaff ||
      contact.cannotTransactOnline ||
      contact.disciplinary ||
      contact.unpaidFine ||
      contact.personOfInterest ||
      contact.discussWithRegistrar ||
      contact.competenceProgramme
   );
};

export const getDisplayContactName = (
   contact?: Contact,
   defaultValue = ''
): string => {
   return contact &&
      contact.contactTypeCode &&
      COMPANY_CONTACT_TYPES.some(ct => ct === contact.contactTypeCode)
      ? (contact.companyName || defaultValue).trim()
      : contact
      ? contact.fullName || defaultValue
      : defaultValue;
};

export const getDisplayDateOrUrl = (
   contact?: Contact,
   defaultValue = '-'
): string => {
   return contact &&
      contact.contactTypeCode &&
      COMPANY_CONTACT_TYPES.some(ct => ct === contact.contactTypeCode)
      ? ''
      : contact
      ? contact.dateOfBirth
         ? moment(contact.dateOfBirth)
              .tz(NZ_TIMEZONE)
              .format(DISPLAY_DATE_FORMAT)
         : defaultValue
      : defaultValue;
};

export const getDisplayRegistrationNumber = (
   contact?: Contact,
   defaultValue = ''
): string => {
   if (contact && contact.contactTypeCode) {
      switch (contact.contactTypeCode) {
         case CONTACT_TYPE_EMPLOYER:
            return contact.registrationNumber
               ? formatRegistrationNumber(
                    'E' + contact.registrationNumber.toString()
                 )
               : defaultValue;
         case CONTACT_TYPE_TRAINING_PROVIDER:
            return 'TP' + contact.contactId;
         case CONTACT_TYPE_PRACTITIONER:
            return contact.registrationNumber
               ? formatRegistrationNumber(contact.registrationNumber.toString())
               : defaultValue;
         default:
            return defaultValue;
      }
   }
   return defaultValue;
};
