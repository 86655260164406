import { DEFAULT_COUNTRY_CODE } from 'Util/Constants/CountryCode';
import { CONTACT_TYPE_PRACTITIONER } from 'Util/Constants/Contact';

interface Gender {
   id: number;
   name: string;
}

interface Ethnicity {
   id: number;
   name: string;
}

interface ContactEdit {
   // string types
   firstName: string;
   lastName: string;
   fullName: string;
   dateOfBirth?: string | null;
   companyName: string;
   emailAddress: string;
   phoneNumber: string;
   faxNumber: string;
   mobileNumber: string;
   auditPostalDistrictCode: string;

   genderId?: number | null;
   gender?: Gender;
   ethnicityId?: number | null;
   ethnicity?: Ethnicity;

   mailingAddressFull: string;
   mailingAddressLine1: string;
   mailingAddressLine2: string;
   mailingSuburbTown: string;
   mailingPostalCode: string;
   mailingCity: string;
   mailingCountryCode: string;

   businessAddressFull: string;
   businessAddressLine1: string;
   businessAddressLine2: string;
   businessSuburbTown: string;
   businessPostalCode: string;
   businessCity: string;
   businessCountryCode: string;

   physicalAddressFull: string;
   physicalAddressLine1: string;
   physicalAddressLine2: string;
   physicalSuburbTown: string;
   physicalPostalCode: string;
   physicalCity: string;
   physicalCountryCode: string;

   photoData: string;
   contactStatusCode: string;
   contactTypeCode: string;
   hideInPublicRegisterReason: string;
   preferredContactInformation: string;
   additionalInfo: string;
   publicPhone: string;
   publicEmail: string;
   url: string;

   // boolean types
   mailingAddressIsValid: boolean;
   businessAddressIsValid: boolean;
   physicalAddressIsValid: boolean;
   excludeContactDetailsFromRegister: boolean;
   optOutSmsnotifications: boolean;
   optOutEmailNotifications: boolean;
   licensingTrainingProvider: boolean;
   subscribeFlag: boolean;
   homeAddressUnknownFlag: boolean;
   hideInPublicRegisterFlag: boolean;
   mobileNumberUnknownFlag: boolean;
   referToRegistrar: boolean;
   discussWithRegistrar: boolean;

   abusiveToStaff: boolean;
   unpaidFine: boolean;
   disciplinary: boolean;
   personOfInterest: boolean;
   cannotSupervisePlumber: boolean;
   cannotSuperviseGasfitter: boolean;
   cannotSuperviseDrainlayer: boolean;
   cannotTransactOnline: boolean;
   competenceProgramme: boolean;

   // number types
   registrationNumber?: number;
   employerId: number;
   postalDeliveryPointId: number;
   homeDeliveryPointId: number;
   postalValidationStatus: number;
   homeValidationStatus: number;

   isIdentityUserCreated?: boolean;

   // only for Edit
   contactId?: number;
}

export type ContactEditModel = Readonly<ContactEdit>;

export const DefaultContactEditModel: ContactEditModel = {
   // string types
   firstName: '',
   lastName: '',
   fullName: '',
   dateOfBirth: '',
   companyName: '',
   emailAddress: '',
   phoneNumber: '',
   faxNumber: '',
   mobileNumber: '',
   auditPostalDistrictCode: '',

   genderId: null,
   ethnicityId: null,

   mailingAddressFull: '',
   mailingAddressLine1: '',
   mailingAddressLine2: '',
   mailingSuburbTown: '',
   mailingPostalCode: '',
   mailingCity: '',

   businessAddressFull: '',
   businessAddressLine1: '',
   businessAddressLine2: '',
   businessSuburbTown: '',
   businessPostalCode: '',
   businessCity: '',

   physicalAddressFull: '',
   physicalAddressLine1: '',
   physicalAddressLine2: '',
   physicalSuburbTown: '',
   physicalPostalCode: '',
   physicalCity: '',
   photoData: '',
   mailingCountryCode: DEFAULT_COUNTRY_CODE,
   businessCountryCode: DEFAULT_COUNTRY_CODE,
   physicalCountryCode: DEFAULT_COUNTRY_CODE,
   contactStatusCode: '',
   contactTypeCode: CONTACT_TYPE_PRACTITIONER, // default
   hideInPublicRegisterReason: '',
   preferredContactInformation: '',
   additionalInfo: '',
   publicPhone: '',
   publicEmail: '',
   url: '',

   // boolean types
   mailingAddressIsValid: false,
   businessAddressIsValid: false,
   physicalAddressIsValid: false,
   excludeContactDetailsFromRegister: false,
   optOutSmsnotifications: false,
   optOutEmailNotifications: false,
   licensingTrainingProvider: false,
   subscribeFlag: true,
   homeAddressUnknownFlag: false,
   hideInPublicRegisterFlag: false,
   mobileNumberUnknownFlag: false,
   referToRegistrar: false,
   discussWithRegistrar: false,

   abusiveToStaff: false,
   unpaidFine: false,
   disciplinary: false,
   personOfInterest: false,
   cannotSupervisePlumber: false,
   cannotSuperviseGasfitter: false,
   cannotSuperviseDrainlayer: false,
   cannotTransactOnline: false,
   competenceProgramme: false,

   // number types
   employerId: 0,
   postalDeliveryPointId: 0,
   homeDeliveryPointId: 0,
   postalValidationStatus: 0,
   homeValidationStatus: 0,
};

export const DefaultTrainingProviderEditModel: ContactEditModel = {
   // string types
   firstName: '',
   lastName: '',
   fullName: '',
   dateOfBirth: '',
   companyName: '',
   emailAddress: '',
   phoneNumber: '',
   faxNumber: '',
   mobileNumber: '',
   auditPostalDistrictCode: '',

   mailingAddressFull: '',
   mailingAddressLine1: '',
   mailingAddressLine2: '',
   mailingSuburbTown: '',
   mailingPostalCode: '',
   mailingCity: '',

   businessAddressFull: '',
   businessAddressLine1: '',
   businessAddressLine2: '',
   businessSuburbTown: '',
   businessPostalCode: '',
   businessCity: '',

   physicalAddressFull: '',
   physicalAddressLine1: '',
   physicalAddressLine2: '',
   physicalSuburbTown: '',
   physicalPostalCode: '',
   physicalCity: '',
   photoData: '',
   mailingCountryCode: DEFAULT_COUNTRY_CODE,
   businessCountryCode: DEFAULT_COUNTRY_CODE,
   physicalCountryCode: DEFAULT_COUNTRY_CODE,
   contactStatusCode: '',
   contactTypeCode: CONTACT_TYPE_PRACTITIONER, // default
   hideInPublicRegisterReason: '',
   preferredContactInformation: '',
   additionalInfo: '',
   publicPhone: '',
   publicEmail: '',
   url: '',

   // boolean types
   mailingAddressIsValid: false,
   businessAddressIsValid: false,
   physicalAddressIsValid: false,
   excludeContactDetailsFromRegister: false,
   optOutSmsnotifications: true,
   optOutEmailNotifications: true,
   licensingTrainingProvider: false,
   subscribeFlag: false,
   homeAddressUnknownFlag: false,
   hideInPublicRegisterFlag: false,
   mobileNumberUnknownFlag: false,
   referToRegistrar: false,
   discussWithRegistrar: false,

   abusiveToStaff: false,
   unpaidFine: false,
   disciplinary: false,
   personOfInterest: false,
   cannotSupervisePlumber: false,
   cannotSuperviseGasfitter: false,
   cannotSuperviseDrainlayer: false,
   cannotTransactOnline: false,
   competenceProgramme: false,

   // number types
   employerId: 0,
   postalDeliveryPointId: 0,
   homeDeliveryPointId: 0,
   postalValidationStatus: 0,
   homeValidationStatus: 0,
};

type BusinessAddress = Pick<
   ContactEditModel,
   | 'businessAddressLine1'
   | 'businessAddressLine2'
   | 'businessSuburbTown'
   | 'businessPostalCode'
   | 'businessCity'
   | 'businessCountryCode'
>;

export const getBusinessAddress = (
   model: ContactEditModel
): BusinessAddress => {
   return {
      businessAddressLine1: model.businessAddressLine1,
      businessAddressLine2: model.businessAddressLine2,
      businessSuburbTown: model.businessSuburbTown,
      businessPostalCode: model.businessPostalCode,
      businessCity: model.businessCity,
      businessCountryCode: model.businessCountryCode,
   };
};

type MailingAddress = Pick<
   ContactEditModel,
   | 'mailingAddressLine1'
   | 'mailingAddressLine2'
   | 'mailingSuburbTown'
   | 'mailingPostalCode'
   | 'mailingCity'
   | 'mailingCountryCode'
>;

export const getMailingAddress = (model: ContactEditModel): MailingAddress => {
   return {
      mailingAddressLine1: model.mailingAddressLine1,
      mailingAddressLine2: model.mailingAddressLine2,
      mailingSuburbTown: model.mailingSuburbTown,
      mailingPostalCode: model.mailingPostalCode,
      mailingCity: model.mailingCity,
      mailingCountryCode: model.mailingCountryCode,
   };
};

type PhysicalAddress = Pick<
   ContactEditModel,
   | 'physicalAddressLine1'
   | 'physicalAddressLine2'
   | 'physicalSuburbTown'
   | 'physicalPostalCode'
   | 'physicalCity'
   | 'physicalCountryCode'
>;

export const getPhysicalAddress = (
   model: ContactEditModel
): PhysicalAddress => {
   return {
      physicalAddressLine1: model.physicalAddressLine1,
      physicalAddressLine2: model.physicalAddressLine2,
      physicalSuburbTown: model.physicalSuburbTown,
      physicalPostalCode: model.physicalPostalCode,
      physicalCity: model.physicalCity,
      physicalCountryCode: model.physicalCountryCode,
   };
};
